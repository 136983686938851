<template>

    <aside  class="flex gap-0 transition ease-in-out delay-150 ">
      <Sidebar />
      <div class="flex-1" >
     
       <Header  @messageToHeader="receiveMessageFromHomeView"/>
        <router-view />
        <Footer/>
      </div>
    </aside>
    



</template>

<script>
import axios from 'axios'
import Header from "@/components/Header.vue"
import Sidebar from "@/components/Sidebar.vue"
import {ref} from 'vue'
import Footer from '@/components/Footer.vue'
export default{
  components:{Header,Sidebar ,Footer},
   methods: {
    receiveMessageFromHomeView(message) {
      console.log(message); // Burada gönderilen mesajı alabilirsiniz
    }
   },
}
</script>
<style>


</style>
