<template>
  <div v-if="!products.length">
    <section class="bg-white dark:bg-gray-900">
      <div class="container px-6 py-10 mx-auto animate-pulse">
        <div
          class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3"
        >
          <div class="w-full">
            <div
              class="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"
            ></div>

            <h1
              class="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></h1>
            <p
              class="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></p>
          </div>

          <div class="w-full">
            <div
              class="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"
            ></div>

            <h1
              class="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></h1>
            <p
              class="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></p>
          </div>

          <div class="w-full">
            <div
              class="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"
            ></div>

            <h1
              class="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></h1>
            <p
              class="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></p>
          </div>

          <div class="w-full">
            <div
              class="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"
            ></div>

            <h1
              class="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></h1>
            <p
              class="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></p>
          </div>

          <div class="w-full">
            <div
              class="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"
            ></div>

            <h1
              class="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></h1>
            <p
              class="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></p>
          </div>

          <div class="w-full">
            <div
              class="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"
            ></div>

            <h1
              class="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></h1>
            <p
              class="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></p>
          </div>

          <div class="w-full">
            <div
              class="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"
            ></div>

            <h1
              class="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></h1>
            <p
              class="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></p>
          </div>

          <div class="w-full">
            <div
              class="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"
            ></div>

            <h1
              class="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></h1>
            <p
              class="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"
            ></p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div
    v-if="products.length"
    style="
      height: 91vh;
      overflow-x: auto;
      overflow-y: scroll;
      scrollbar-width: none;
    "
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-3"
  >
    <div
      v-for="product in products"
      :key="product.id"
      class="col-span-1 md:col-span-1 p-3 flex justify-center max-h-60"
    >
      <div
        class="flex max-w-md justify-center align-center overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800"
      >
        <div
          class="w-1/3 bg-cover"
          :style="{
            backgroundImage: 'url(' + product.image + ')',
            height: '210px',
          }"
        ></div>

        <div class="w-2/3 p-4 md:p-4">
          <router-link :to="{ path: '/product/' + product.id }">
            <h1 class="text-xl font-bold text-gray-800 dark:text-white">
              {{ titleKes(product.title, 20) }}...
            </h1>
          </router-link>

          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {{ titleKes(product.description, 50) }} ..
          </p>

          <div class="flex mt-2 item-center">
            <svg
              class="w-5 h-5 text-gray-700 fill-current dark:text-gray-300"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
              />
            </svg>

            <svg
              class="w-5 h-5 text-gray-700 fill-current dark:text-gray-300"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
              />
            </svg>

            <svg
              class="w-5 h-5 text-gray-700 fill-current dark:text-gray-300"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
              />
            </svg>

            <svg class="w-5 h-5 text-gray-500 fill-current" viewBox="0 0 24 24">
              <path
                d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
              />
            </svg>

            <svg class="w-5 h-5 text-gray-500 fill-current" viewBox="0 0 24 24">
              <path
                d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
              />
            </svg>
          </div>

          <div class="flex justify-between mt-3 item-center">
            <h1
              class="text-lg font-bold text-gray-700 dark:text-gray-200 md:text-xl"
            >
              ${{ product.price }}
            </h1>

            <button
              @click.prevent="sendMessageToHeader(product.id)"
              class="px-2 py-1 text-xs font-bold text-white uppercase transition-colors duration-300 transform bg-gray-800 rounded dark:bg-gray-700 hover:bg-gray-700 dark:hover:bg-gray-600 focus:outline-none focus:bg-gray-700 dark:focus:bg-gray-600"
            >
              <i class="fa-solid fa-basket-shopping"></i>
              <span class="ms-2"> Add Basket </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import EventBus from "@/EventBus.js";
// @ is an alias to /src
import axios from "axios";

export default {
  name: "HomeView",
  data() {
    return {
      products: [],
      products2: [],
    };
  },
  created() {
    axios
      .get("https://fakestoreapi.com/products")
      .then((res) => {
        this.products = res.data;
        this.products2 = res.data;
        console.log(this.products);
      })
      .catch((err) => {
        console.log(err);
      });

    EventBus.on("Bulunanlar", (newValue) => {
      console.log(newValue);

      if (newValue) {
        const urunler = this.products2.filter((urun) =>
          urun.title.includes(newValue)
        );
        if (urunler.length >= 1) {
          this.products = urunler;
          console.log("URUNLER => ", urunler);
          console.log(urunler.length);
        } else {
          this.products = []; // Tüm ürünleri getir
        }
      } else {
        this.getProducts(); // Tüm ürünleri getir
      }
      this.previousValue = newValue; // Önceki değeri güncelle
    });
  },
  methods: {
    getProducts() {
      axios
        .get("https://fakestoreapi.com/products")
        .then((res) => {
          this.products = res.data;
          this.products2 = res.data;
          console.log(this.products);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {},

  methods: {
    getProducts() {
      axios
        .get("https://fakestoreapi.com/products")
        .then((res) => {
          this.products = res.data;
          console.log(this.products);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    titleKes(title, uzunluk) {
      return title.slice(0, uzunluk);
    },
    sendMessageToHeader(id) {
      EventBus.emit("dataToComponentB", id);
    },
  },
};
</script>