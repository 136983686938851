<template>
    <div class="grid">
<div v-if="!product" class="flex justify-center align-center">
    <div class="w-full max-w-lg mx-auto animate-pulse p-9 mt-20">
        <h1 class="h-2 bg-gray-300 rounded-lg w-52 dark:bg-gray-600"></h1>
    
        <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
        <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
        <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
        <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
    </div>
</div>
   
    <div v-if="product" class="flex items-center py-4 ms-7 overflow-x-auto whitespace-nowrap ">
        <router-link :to="{path:'/'}" class="text-gray-600 dark:text-black">
            <i class="fa-solid fa-home "></i>
        </router-link>
    
        <span class="mx-5 text-gray-500 dark:text-black">
            /
        </span>
    
        <router-link :to="{path:'/'}" class="text-gray-600 dark:text-black hover:underline">
            Products
        </router-link>

        <span class="mx-5 text-gray-500 dark:text-gray-300">
            /
        </span>
    
        <router-link :to="{path:/product/+product.id}" class="text-blue-600 dark:text-blue-400 hover:underline">
            {{ titleKes(product.title,100) }}
        </router-link>
    </div>
    <div v-if="product"   class="col-span-1 md:col-span-1 p-3 flex justify-center mt-4 ">
    
        <section class="bg-white border-t-8 border-e border-b rounded-lg ">
            <div class="container px-6 py-10 mx-auto  rounded-lg">
                <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-gray-800">{{titleKes(product.title,100)}}</h1>
        
                <div class="mt-8 lg:-mx-6 lg:flex lg:items-center">
                    <img class="object-contain w-full h-full lg:mx-6 lg:w-1/2 rounded-xl h-72 lg:h-96" :src="product.image" alt="">
        
                    <div class="mt-6 lg:w-1/2 lg:mt-0 lg:mx-6 ">
                        <p class="text-sm text-blue-500 uppercase">{{product.category}}</p>
        
                        <span class="block mt-4 text-2xl font-semibold text-gray-800 dark:text-gray-800">
                            {{product.title}}
                        </span>
        
                        <p class="mt-3 text-sm text-gray-500 dark:text-gray-500 md:text-sm">
                            {{titleKes(product.description,200)}}
                        </p>
                        <div class="flex mt-2 item-center">
                            <svg class="w-5 h-5 text-gray-700 fill-current dark:text-gray-700" viewBox="0 0 24 24">
                                <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                            </svg>
                
                            <svg class="w-5 h-5 text-gray-700 fill-current dark:text-gray-700" viewBox="0 0 24 24">
                                <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                            </svg>
                
                            <svg class="w-5 h-5 text-gray-700 fill-current dark:text-gray-700" viewBox="0 0 24 24">
                                <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                            </svg>
                
                            <svg class="w-5 h-5 text-gray-500 fill-current" viewBox="0 0 24 24">
                                <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                            </svg>
                
                            <svg class="w-5 h-5 text-gray-500 fill-current" viewBox="0 0 24 24">
                                <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                            </svg>
                            <span class="text-xs mx-1 font-normal text-white bg-blue-500 rounded-full py-0.5 px-1.5">{{product.rating.rate}}</span>
                        </div>
                        <button  @click.prevent="sendMessageToHeader(product.id)" class="inline-flex items-center justify-center  px-6 py-2 my-2 text-md text-white duration-300 bg-blue-500 rounded-lg hover:bg-gray-800 focus:ring focus:ring-gray-300 focus:ring-opacity-80">
                            <i class="fa-solid fa-basket-shopping "></i> <span class="ms-2"> ${{ product.price }} </span>
                        </button>
                       
                        <div class="flex items-center mt-6">
                            <img class="object-cover object-center w-10 h-10 rounded-full" src="https://images.unsplash.com/photo-1531590878845-12627191e687?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt="">
        
                            <div class="mx-4">
                                <h1 class="text-sm text-gray-700 dark:text-gray-700">Amelia. Anderson</h1>
                                <p class="text-sm text-gray-500 dark:text-gray-600">Lead Developer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
    </div>
</div>
</template>

<script>
import axios from 'axios'
import EventBus from '@/EventBus'
export default {
    components:{},
    
    data() {
        return {
            product:"",
        }
    },
    methods: {
        sendMessageToHeader(id) {
      EventBus.emit("dataToComponentB", id);
    },
        titleKes(title,uzunluk){
      return title.slice(0,uzunluk);
    }
    },
  mounted() {
    axios.get('https://fakestoreapi.com/products/'+this.$route.params.id)
    .then((res)=>{
     this.product = res.data;
     console.log("Bulunan urun =>",res.data.title);
    })
    .catch((err)=>{console.log(err)})
  },

}
</script>

<style>

</style>