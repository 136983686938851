<template>
  <nav :x-data="isOpen" class="bg-white shadow dark:bg-gray-800">
    <div class="container px-20 py-2 mx-auto">
      <div class="lg:flex lg:items-center">
        <!--HEADER BASLANGICI-->
        <div class="flex items-center justify-between">
          <router-link :to="{path:'/'}">
            <img
              style="height: 50px !important"
              class="w-auto"
              src="/vgrgb.png"
              alt=""
            />
          </router-link>
          
          <!-- Mobile menu button -->
          <div class="flex lg:hidden"></div>
          <div style=" scrollbar-width: none; scrollbar-base-color: transparent" class="flex lg:hidden scrollgizle">
            <button  data-tooltip-target="tooltip-bottom" data-tooltip-trigger="click" type="button"  class="px-3 py-2 me-3 text-xs font-bold text-white uppercase transition-colors duration-300 transform bg-gray-800 rounded-xl dark:bg-gray-700 hover:bg-gray-700 dark:hover:bg-gray-600 focus:outline-none focus:bg-gray-700 dark:focus:bg-gray-600">
                <i class="fa-solid fa-basket-shopping"></i>
                <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-blue-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">{{inBasket.length}}</div>
            </button>

            <div style=" scrollbar-width: none; scrollbar-base-color: darkslategrey;"  id="tooltip-bottom" role="tooltip" class=" scrollgizle absolute z-50 invisible inline-block  text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700  w-38 max-w-38  overflow-y-scroll  max-h-80">
                <div class="grid grid-cols-1 p-7 max-w-80 overflow-y-auto ">
                    <div class="p-5 border border-gray-100 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700 scrollgizle ">
                      
                        <ol  class="mt-3 divide-y divider-gray-200 dark:divide-gray-700  scrollgizle">
                            <li  v-for="product in basketProducts" :key="product.id">
 
                                <a href="#" class=" flex items-center block p-3 my-2  sm:flex hover:bg-gray-100 dark:hover:bg-gray-700  hover:animate-pulse transition-all rounded-md border-gray-600 border-e-0 border-b-0 ">
                                    <img class="w-10 h-10 mb-3 me-3 object-contain rounded-full " :src="product.image" alt="Laura Romeros image"/>
                                    <div class="text-gray-600 dark:text-gray-400">
                                        <div class="text-xs font-bold">
                                           {{ titleKes(product.title,20)}}...
                                        </div>
                                        <div class="text-xs font-normal"> {{ titleKes(product.description,30)}}..</div>
                                        <span class="inline-flex items-center text-xs font-bold text-gray-900 dark:text-gray-100">
                                            
                                           ${{ product.price }}
                                        </span> 
                                    </div>
                                </a>
                            </li>
                           
                           
                           
                           
                           
                            
                        </ol>
                        <time class="text-sm font-semibold text-gray-900 dark:text-white">Total : ${{basketTotal}}</time>
                    </div>
                   
                 
                </div>
                <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <button
              x-cloak
              @click="isOpen = !isOpen"
              type="button"
              class="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
              aria-label="toggle menu"
            >
              <svg
                v-show="!isOpen"
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 8h16M4 16h16"
                />
              </svg>

              <svg
                v-show="isOpen"
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        

        <div
          x-cloak
          :class="[
            isOpen
              ? 'translate-x-0 opacity-100'
              : 'opacity-0 -translate-x-full',
          ]"
          class="absolute lg:block inset-x-0 z-20 flex-1 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white dark:bg-gray-800 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center lg:justify-between"
        >
        
          <div
         
            class="flex flex-col text-gray-600 capitalize dark:text-gray-300 lg:flex lg:px-14 lg:-mx-4 lg:flex-row lg:items-center"
          >
           
            <router-link 
            v-for="category in categories"
            :to="{path:'/category/'+category}"
            :key="category.id"
             
              class="mt-2 transition-colors duration-300 transform lg:mt-0 lg:mx-4 hover:text-blue-700"
              >{{category}}
            </router-link>
           
           
            
           

            <div class="relative mt-4 lg:mt-0 lg:mx-4">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  class="w-4 h-4 text-gray-600 dark:text-gray-300"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </span>

              <input
              @input="inputChanged"
               v-model="ara"
                type="text"
                class="w-full py-1 pl-10 pr-4 text-gray-700 placeholder-gray-600 bg-white border-b border-gray-600 dark:placeholder-gray-300 dark:focus:border-gray-300 lg:w-56 lg:border-transparent dark:bg-gray-800 dark:text-gray-300 focus:outline-none focus:border-gray-600"
                placeholder="Search"

              />
            </div>
            <button   data-tooltip-target="tooltip-bottom2" data-tooltip-trigger="click" type="button"  class=" hidden lg:block px-3 py-2 me-3 text-xs font-bold text-white uppercase transition-colors duration-300 transform bg-gray-800 rounded-xl dark:bg-gray-700 hover:bg-gray-700 dark:hover:bg-gray-600 focus:outline-none focus:bg-gray-700 dark:focus:bg-gray-600">
                <i class="fa-solid fa-basket-shopping"></i>
                <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-blue-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">{{inBasket.length}}</div>
            </button>

            <div style=" scrollbar-width: none; scrollbar-base-color: darkslategrey;"  id="tooltip-bottom2" role="tooltip" class=" scrollgizle absolute z-50 invisible inline-block  text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700  w-38 max-w-38  overflow-y-auto  max-h-96">
                <div class="grid grid-cols-1 p-7 max-w-80 overflow-y-auto ">
                    <div class="p-5 border border-gray-100 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700 scrollgizle ">
                      
                        <ol  class="mt-3 divide-y divider-gray-200 dark:divide-gray-700  scrollgizle">

                            <li  v-for="product in basketProducts" :key="product.id">
 
                                <a  class=" cursor-pointer flex items-center block p-3 my-2  sm:flex hover:bg-gray-100 dark:hover:bg-gray-700  hover:border-0 hover:animate-pulse transition-shadow rounded-md border-gray-600 border-e-0 border-b-0 ">
                                    <img class="w-10 h-10 mb-3 me-3 object-contain rounded-full " :src="product.image" alt="Laura Romeros image"/>
                                    <div class="text-gray-600 dark:text-gray-400">
                                        <div class="text-xs font-bold">
                                           {{ titleKes(product.title,20)}}...
                                        </div>
                                        <div class="text-xs font-normal"> {{ titleKes(product.description,30)}}..</div>
                                        <span class="inline-flex items-center text-xs font-bold text-gray-900 dark:text-gray-100">
                                            
                                           ${{ product.price }}
                                        </span> 
                                    </div>
                                </a>
                            </li>
                           
                           
                           
                           
                           
                            
                        </ol>
                        <time class="text-sm font-semibold text-gray-900 dark:text-white">Total : ${{basketTotal}}</time>
                    </div>
                   
                 
                </div>
                <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
          </div>

          <div class="flex justify-center mt-6 lg:flex lg:mt-0 lg:-mx-2">
            <a
            target="_blank"
              href="https://github.com/vasifcoding"
              class="mx-2 text-gray-600 transition-colors duration-300 transform dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-300"
              aria-label="Reddit"
            >
            <i class="fa-brands fa-github"></i>
            </a>

            <a
            target="_blank"
              href="https://instagram.com/vasifcoding"
              class="mx-2 text-gray-600 transition-colors duration-300 transform dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-300"
              aria-label="Facebook"
            >
            <i class="fa-brands fa-instagram"></i>
            </a>

           
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from 'axios'
import { initFlowbite } from 'flowbite'
import {ref , computed} from 'vue'
// initialize components based on data attribute selectors
import EventBus from "@/EventBus";
export default {
    setup(){
        initFlowbite();
        const ara = ref(null)
        return {ara ,initFlowbite}
        
    },
    watch: {
    // 'count' verisini izle
    ara: function (newValue, oldValue) {
      // Değer değiştiğinde çalışacak kod buraya gelecek
      EventBus.emit("Bulunanlar",newValue)
    }
  },
  data() {
    return {
      isOpen: false,
      inBasket: [],
      seeTheBasket: false,
      products:[],
      basketProducts:[],
      basketTotal:0,
      categories :[],
    };
  },

  methods: {
    seeBasket() {
      this.seeTheBasket = true;
    },
    notSeeBasket() {
      this.seeTheBasket = false;
    },
    titleKes(title,uzunluk){
      return title.slice(0,uzunluk);
    },
  },
created() {

  axios
      .get("  https://fakestoreapi.com/products/categories")
      .then((res) => {
       this.categories = res.data
        console.log(this.products);
      })
      .catch((err) => {
        console.log(err);
      });
    EventBus.on("dataToComponentB", (data) => {
      console.log("GELEN ID => ",data); // 'Hello from Component A'
      // Gelen veriyi kullanabilirsiniz
      this.inBasket.push(data);
      const uniqueArray = [...new Set(this.inBasket)];
      this.inBasket = uniqueArray;
      console.log("SEPETTEKI IDLER =>",this.inBasket);
      axios.get('https://fakestoreapi.com/products')
        .then((res)=>{
          this.products = res.data;
          console.log(res.data);
          
          const selectedProducts = this.products.filter(product => this.inBasket.includes(product.id));
          console.log(selectedProducts);
          this.basketProducts = selectedProducts;
          const total = selectedProducts.reduce((accumulator, currentProduct) => {
    return accumulator + currentProduct.price;
}, 0);
this.basketTotal = total;
          console.log(this.inBasket);
        })
        .catch((err)=>{console.log(err)});
    });
  },

};
</script>

<style>
.scrollgizle::-webkit-scrollbar {
    display: none;

  }
  .scrollgizle{
    scrollbar-width: none;
  }
</style>